<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">情绪值测试</h1>
      <div class="content1">亲爱的同事：<br /></div>

      <div class="content">
        本测评目的在于帮助您了解当前情绪状态，促进自我觉察、助力您提升心理调节能力。我们将严格保护您的个人信息，请您放心作答。
      </div>
      <br />
      <button class="start-button" style="margin-right: 10px" @click="golist">
        历史报告
      </button>

      <button class="start-button" @click="startAssessment">开始测评</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      userId: "",
    };
  },
  methods: {
    golist() {
      this.$router.push("/CP/huawei/list");
    },
    startAssessment() {
      if (!this.userId || this.userId == "undefined") {
        Toast("微信授权登录失败");
        // setTimeout(() => {
        //   this.$router.push("/cp/huawei/login");
        // }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/huawei/answer");
    },
  },
  created() {
    this.userId = this.$route.query.userId;
    localStorage.setItem("userId", this.userId);
    console.log(this.userId);
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
}

.intro-container {
  max-width: 720px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1.5em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
}
.jiacu {
  /* 加粗 */
  font-weight: bold;
}
</style>
